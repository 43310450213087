import React, { useState, useEffect } from "react";
import HeaderNav from "../../Components/HeaderNav/HeaderNav";
import NewFooter from "../../Components/NewFooter";
import classes from './Archive.module.css';
import { Form, Row, Col, FormGroup, Button, Spinner } from "react-bootstrap";
import DocIcon from '../../assets/pdff.png';
import { useLocation } from "react-router-dom";
import FilterIcon from '../../assets/FilterIcon.svg'
import PdfFile8 from '../../../src/doc/pdf8.pdf';
import { BASE_URL, TOKEN } from "../../BaseUrl/BaseUrl";
import axios from "axios";
import { Pagination } from "react-bootstrap";
import NewFooter2 from "../../Components/NewFooter2";


function Auditor() {
    const [selectedOption, setSelectedOption] = useState('');
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalEntries, setTotalEntries] = useState("");
    const [totalPages, setTotalPages] = useState("");
 
  
 
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const toggleFilter = () => {
        setIsFilterOpen(!isFilterOpen); 
    };

    const closeFilter = () => {
        setIsFilterOpen(false); 
    };


    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };



    const fetchDocuments = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/get-archive-record`, {
                headers: {
                    'Authorization': `Bearer ${TOKEN}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    id: "71",
                    page: currentPage
                }
            });
            const results = response?.data?.data?.data;
            setDocuments(results);
            setTotalEntries(response?.data?.data?.total);
            const total = response?.data?.data?.last_page || 1;
            setTotalPages(total);
            console.log(results);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, [currentPage]);

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        window.scrollTo(0, 0); 
    };
    
    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
        window.scrollTo(0, 0); 
    };
      
      const formattedTotalEntries = totalEntries;
      
      function formatDate(dateString) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
        const date = new Date(dateString);
        const dayOfWeek = days[date.getDay()];
        const month = months[date.getMonth()];
        const dayOfMonth = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; 
        const formattedDate = `${dayOfWeek}, ${month}, ${dayOfMonth} ${year} at ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
        return formattedDate;
    }


    return (

        <div>
            <HeaderNav />
            <div className={classes.archvTable}>
                {/* <p className={classes.tablep}>Archives</p> */}
                <div className={classes.mainContainter}>
                    <div className={classes.leftContainer}>
                        <div className={classes.filterText} onClick={toggleFilter}>
                            <h5 className={classes.filt} >Filter</h5>
                            <img src={FilterIcon} alt="Icon" />
                            {/* <span className={classes.filterIcon}>icon</span> */}
                        </div>
                        <div className={`${classes.filterBox} ${isFilterOpen ? '' : classes.unActive}`}>
                            <Form.Group className={classes.formGroup} >
                                <Form.Label className={classes.label}>MDA:</Form.Label>
                                <Form.Select >
                                    <option>Ministry</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className={classes.formGroup} >
                                <Form.Label className={classes.label}>Type:</Form.Label>
                                <Form.Select >
                                    <option>Ministry of Justice</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className={classes.formGroup} >
                                <Form.Label className={classes.label}>Year:</Form.Label>
                                <Form.Select >
                                    <option>2022</option>
                                </Form.Select>
                            </Form.Group>

                            <button className={classes.dwnldbtn1}>Search</button>
                        </div>
                    </div>

                    <div className={classes.rightContainer}>
                        <h1 className={classes.archHeader}>STATE AUDITORS REPORT</h1>
                        <p>Showing {`Page ${currentPage} to ${totalPages} pages`} of {formattedTotalEntries} entries
                        </p>

                        <div className={classes.archivGco}>
                            {/* <div></div> */}
                            {loading ? (
                                <>
                                <Spinner size="sm" />
                                <span>Loading...</span>
                                </>
                            ) : documents.length === 0 ? (
                                <p>No documents to display</p>
                            ) : (
                            <div className={classes.gridContainer}>
                            {documents.map((item, index) => (
                                <div key={index} className={classes.archContainer}>
                                <div className={classes.archiveFlex}>
                                    <img src={DocIcon} style={{ height: 70, width: 70 }} alt="Ogun Logo" />
                                    <div className={classes.archSub}>
                                    <h5 className={classes.archTitle}>{item.name}</h5>
                                    <p>{item.description}</p>
                                    <p>Uploaded on: {formatDate(item.created_date)}</p>
                                    </div>
                                    <a href={item.file} target="blank" className={`${classes.dwnldbtn2} ${classes.webShow}`}>Download</a>
                                </div>
                                <div className={classes.directions}>
                                
                                    <a href={item.file} target="blank" className={`${classes.dwnldbtn2} ${classes.mobileShow}`}>Download</a>
                                </div>
                                </div>
                            ))}
                            </div>
                            )}
                        </div>

                        <div className={classes.endded}>
                             
                              <div style={{ display: 'flex' }}>
                                <button
                                  style={{ border: 'none', textAlign: "center", backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: "pointer" }}
                                  onClick={handlePrevPage}
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>
                                {[...Array(totalPages)].map((_, page) => {
                                  // Show only 5 pages or less if available
                                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                    return (
                                      <button
                                        key={page + 1}
                                        style={{
                                          marginLeft: '0.4rem',
                                          marginRight: '0.4rem',
                                          fontSize: '14px',
                                          fontFamily: 'nunito',
                                          fontWeight: 400,
                                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                          height: '2.5rem',
                                          borderRadius: '89px',
                                          padding: '0.5rem',
                                          border: 'none',
                                          width: '40px',
                                          cursor: "pointer",
                                          textAlign: "center",
                                          fontFamily: "inter"
                                        }}
                                        onClick={() => {
                                            setCurrentPage(page + 1);
                                            window.scrollTo(0, 0); 
                                        }}
                                      >
                                        {page + 1}
                                      </button>
                                    );
                                  }
                                  return null;
                                })}
                                <button
                                  style={{ border: 'none', textAlign: "center", backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: "pointer" }}
                                  onClick={handleNextPage}
                                  disabled={currentPage === totalPages}
                                >
                                  Next
                                </button>
                              </div>
                            </div>


                    </div>

                </div>
                {/* <Button></Button> */}
                <div className={classes.empty}>
                </div>
            </div>
            <NewFooter2 />
        </div>


    )
}
export default Auditor;
