import React, { useState, useEffect } from "react";
import classes from './Executives.module.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Carousel, Spinner } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import footerLogo from "../../assets/Footer-logo.svg"
import { BASE_URL, TOKEN } from "../../BaseUrl/BaseUrl";
import axios from "axios";
import NewsIcon from "../../assets/olumo.png"
import NewFooter from "../../Components/NewFooter";
import NewFooter2 from "../../Components/NewFooter2";
import { WiTime8 } from "react-icons/wi";

// function App() {
const NewsDetails = () => {
  const location = useLocation();
  const { selectedNews } = location.state || {};
  console.log(selectedNews);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [currentYear, setCurrentYear] = useState('');
  const imageUrl = selectedNews?.banner ? BASE_URL + selectedNews.banner : null;

  useEffect(() => {
    const latestYear = new Date().getFullYear();
    setCurrentYear(latestYear);
  }, []);


  function formatDate(dateString) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const date = new Date(dateString);
    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedDate = `${dayOfWeek}, ${month}, ${dayOfMonth} ${year} at ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    return formattedDate;
  }

  const handleReadMore = (item) => {
    navigate('/read_news', { state: { selectedNews: item } });
  }

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-news`, {
        headers: {
          'Authorization': `Bearer ${TOKEN}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: currentPage
        }
      });
      const results = response?.data?.data?.data;
      setDocuments(results);
      setTotalEntries(response?.data?.data?.total);
      const total = response?.data?.data?.last_page || 1;
      setTotalPages(total);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [currentPage]);

  const shuffleArray = (array) => {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const cleanHtml = (html) => {
    // Replace multiple line breaks with a single one
    return html.replace(/(<br\s*\/?>\s*){2,}/g, '<br>').replace(/\n{2,}/g, '\n');
  };

  return (
    <div>

      <HeaderNav />

      <div className={classes.wrapper}>
        <div className={classes.topSection}>
          <h1 className={classes.newsTitle}>{selectedNews?.title}</h1>
          <div className={classes.newsGroup}>

            <div className={classes.leftside}>
              {/* <p>{selectedNews?.short_desc}</p> */}
              {selectedNews?.banner ? (
                <img
                  src={selectedNews?.banner}
                  alt="news"
className={classes.newsImage}
                  
                />
              ) : (
                <img
                  src={NewsIcon}
                  alt="news icon"
                  style={{ objectFit: 'block', width: 780, height: 470 }}
                />
              )}
              <div className={classes.postedSection}>
                <div style={{ display: "flex", gap: 5, alignItems: "center", marginTop: -20 }}>
                  <WiTime8 style={{ color: "#000" }} />
                  <div className={classes.dated}>{formatDate(selectedNews.created_at)}</div>
                </div>
                {/* <p className={classes.date}>Posted {formatDate(selectedNews?.created_date)}</p> */}
                <div style={{ marginTop: 20 }} />
                <p
                  dangerouslySetInnerHTML={{ __html: cleanHtml(selectedNews?.long_desc) }}
                  className={classes.mainNews}
                />
              </div>


            </div>
            <div className={classes.rightSide}>
              <h2>More Posts</h2>
              {loading ? (
                <Spinner />
              ) : (
                shuffleArray(documents)?.slice(0, 5).map((doc, index) => (
                  <div key={index} className={classes.newsItem}>
                    <img
                      src={doc.banner}
                      alt={doc.title}
                      style={{ width: '400px', height: '200px', objectFit: 'cover' }}
                    />
                    <p
                      style={{
                        textAlign: 'left',
                        fontSize: '14px',
                        fontWeight: '700',
                        color: '#000',
                      }}
                    >
                      {doc.title}
                    </p>
                    <h6
                      className={classes.readd}
                      onClick={() => handleReadMore(doc)}>Read more...</h6>
                  </div>
                ))
              )}

            </div>

          </div>
        </div>


      </div>
      <NewFooter2 />
    </div>
  );
}

export default NewsDetails;