import React, { useState, useEffect, useLayoutEffect } from "react";
import classes from './Executives.module.css'
import { Link, useNavigate } from "react-router-dom";
import { Button, Carousel, Spinner } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import Dapo from "../../assets/executives-icon/dapoo.png";
import Noimot from "../../assets/executives-icon/noimot.png";
import CommissionerIcon from "../../assets/executives-icon/icon.png";
import SSG1 from "../../assets/ssg/talabi.png";
import SSG2 from "../../assets/ssg/afolabi.png";
import COMM1 from "../../assets/commissioners/a.png";
import COMM2 from "../../assets/commissioners/b.png";
import COMM3 from "../../assets/commissioners/c.png";
import COMM4 from "../../assets/commissioners/d.png";
import COMM5 from "../../assets/commissioners/e.png";
import COMM6 from "../../assets/commissioners/f.png";
import COMM7 from "../../assets/commissioners/g.png";
import COMM8 from "../../assets/commissioners/h.png";
import COMM9 from "../../assets/commissioners/i.png";
import footerLogo from "../../assets/Footer-logo.svg"
import NewsIcon from "../../assets/olumo.png"
import ButtonIcon from "../../assets/button.png"
import { BASE_URL, TOKEN } from "../../BaseUrl/BaseUrl";
import axios from "axios";
import NewFooter from "../../Components/NewFooter";
import NewFooter2 from "../../Components/NewFooter2";
// function App() {
const News = () => {
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState('');
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    const latestYear = new Date().getFullYear();
    setCurrentYear(latestYear);
  }, []);

  useLayoutEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    checkScreenWidth();

    window.addEventListener('resize', checkScreenWidth);
    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);


  const handleReadMore = (item) => {
    navigate('/read_news', {state: {selectedNews: item}});
  }

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-news`, {
        headers: {
          'Authorization': `Bearer ${TOKEN}`,
          'Content-Type': 'application/json'
      },
        params: {
          page: currentPage
        }
      });
      const results = response?.data?.data?.data;
      setDocuments(results);
      setTotalEntries(response?.data?.data?.total);
      const total = response?.data?.data?.last_page || 1;
      setTotalPages(total);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [currentPage]);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    window.scrollTo(0, 0);
  };

  const formattedTotalEntries = totalEntries;

  function formatDate(dateString) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const date = new Date(dateString);
    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedDate = `${dayOfWeek}, ${month}, ${dayOfMonth} ${year} at ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    return formattedDate;
  }


  return (
    <div>

      <HeaderNav />

      <div className={classes.wrapper1}>

        <div className={classes.topSection}>
          <h1>News</h1>
          <p className={classes.instruction}>Read the latest news from Ogun State</p>
          <div className={classes.newGrid}>
          {loading ? (
            <>
              <Spinner size="sm" />
              <span>Fetching news...</span>
            </>
          ) : documents.length === 0 ? (
            <p>No news to display</p>
          ) : (
            documents.map((item, index) => (
              
                <div key={index} className={classes.topSectionLabel}>
                  <div className={classes.newsSection}>
                    <img
                      src={NewsIcon}
                      alt="news"
                      style={{ objectFit: 'contain', width: 170, height: 170, borderRadius: 4 }}
                      className={classes.newImg}
                    />
                    <div className={classes.newsText}>
                      <h1 className={classes.newsTitle}>{item?.title} </h1>
                      <p >{item?.short_desc}</p>
                      <div className={classes.footerButton}>
                        <p >Posted on {formatDate(item.created_date)}</p>
                        <h6 onClick={() => handleReadMore(item)}>Read more...</h6>
                      </div>
                    </div>
                  </div>
                </div>
              // </div>
              
            ))
          )}
          </div>
      <div className={classes.endded}>

      <div style={{ display: 'flex' }}>
        {!isMobile && (
          <button
            style={{ border: 'none', textAlign: 'center', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: 'pointer' }}
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
        )}

        {[...Array(totalPages)].map((_, page) => {
          // Show only 5 pages or less if available
          if (!isMobile && (page < 5 || page === currentPage - 1 || page === totalPages - 1)) {
            return (
              <button
                key={page + 1}
                style={{
                  marginLeft: '0.4rem',
                  marginRight: '0.4rem',
                  fontSize: '14px',
                  fontFamily: 'nunito',
                  fontWeight: 400,
                  color: page + 1 === currentPage ? '#ffffff' : '#000',
                  backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                  height: '2.5rem',
                  borderRadius: '89px',
                  padding: '0.5rem',
                  border: 'none',
                  width: '40px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontFamily: 'inter'
                }}
                onClick={() => {
                  setCurrentPage(page + 1);
                  window.scrollTo(0, 0);
                }}
              >
                {page + 1}
              </button>
            );
          }
          return null;
        })}

        {!isMobile && (
          <button
            style={{ border: 'none', textAlign: 'center', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: 'pointer' }}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        )}

        {isMobile && (
          <div style={{ display: 'flex' }}>
            <button
              style={{ border: 'none', textAlign: 'center', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: 'pointer' }}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              style={{ border: 'none', textAlign: 'center', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'inter', color: '#000', marginRight: 10, cursor: 'pointer' }}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>
      </div>


        </div>

      </div>
      
      <NewFooter2/>
    </div>
  );
}

export default News;